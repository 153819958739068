import React from 'react'
import { connect } from 'react-redux'

import { Alert } from 'react-native'
import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import type { TScrollViewProps } from '@/components/wrappers/ScrollView'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import type { TApiCallProps } from '@/hocs/withApiCall'
import withApiCall from '@/hocs/withApiCall'
import { apiCall as apiCallDispatch, apiCallSuccess } from '@/redux/actions/api'
import {
  resetFormClaimDamanage,
  setFormClaimDamageId,
  updateFormClaimDamageDynamic,
  updateFormClaimDamageStatic
} from '@/redux/actions/forms'
import { removeClaimAttachment, uploadClaimAttachment } from '@/redux/actions/loading'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import type { IApiResponse } from '@/utils/api_wrapper'
import * as myAppClaimsApi from '@/redux/api/my_app_claims'
import type { State as TState } from '@/redux/reducers'
import type { FormClaimDamage } from '@/redux/reducers/forms'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'

import type * as Types from '@/types/objects'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import Stepper from '@/screens/claim_damage/components/Stepper'
import OverlaySpinner from '@/components/overlay_spinner'
import StepResult from './components/StepResult'
import StepStatic from './components/step_static'
import StepSummary from './components/step_summary'
import type { TNavigationProp } from '@/types/props'
import StepExtern from './components/step_extern'
import dateUtils from '@/utils/date'

type TClaimDamageFormProps = TApiCallProps & {
  agency: Types.Agency
  claim?: Types.Claim
  claimDamageId?: Types.Claim['claimDamageId']
  claimMethod?: Types.IClaimMethod
  colors: ThemeColors
  emptyQuestions: any[]
  formClaimDamage: FormClaimDamage
  formResult: Types.FormClaimDamageResult
  loadingClaimAttachments: any
  loadingQuestions: boolean
  loadingPolicyProduct: boolean
  navigation: TNavigationProp
  policy: Types.Policy
  currentRelation: Types.Relation
  user: Types.User

  apiCallDispatch: typeof apiCallDispatch
  apiCallSuccess: typeof apiCallSuccess
  openModalEmergencyNumber: typeof openModalEmergencyNumber
  removeClaimAttachment: typeof removeClaimAttachment
  resetFormClaimDamanage: typeof resetFormClaimDamanage
  setFormClaimDamageId: typeof setFormClaimDamageId
  updateFormClaimDamageDynamic: typeof updateFormClaimDamageDynamic
  updateFormClaimDamageStatic: typeof updateFormClaimDamageStatic
  uploadClaimAttachment: typeof uploadClaimAttachment
}

interface TClaimDamageFormState {
  availableIndex: number
  index: number
  loadingStatic: boolean
  loadingSummary: boolean
  savingProgress: boolean
  showError: boolean
  id: null | string
  loadingFiles: boolean
  conceptButtonText: string
  descriptionError: boolean
  phoneNumberError: boolean
  dateOfDamageError: boolean
  serviceAvailable: boolean
  serviceUnavailableErrorMessage: string
  dateOfDamageErrorMessage: string
}

class ClaimDamageForm extends React.Component<TClaimDamageFormProps, TClaimDamageFormState> {
  stepperItems: string[] = [
    i18n.t('Algemeen'),
    i18n.t('Overzicht')
  ]

  scrollViewRef: TScrollViewProps['scrollViewRef'] = React.createRef()

  state = {
    availableIndex: 0,
    index: 0,
    loadingStatic: false,
    savingProgress: false,
    loadingSummary: false,
    showError: false,
    id: null,
    loadingFiles: false,
    conceptButtonText: 'Concept opslaan',
    descriptionError: false,
    phoneNumberError: false,
    dateOfDamageError: false,
    serviceAvailable: true,
    serviceUnavailableErrorMessage: '',
    dateOfDamageErrorMessage: ''
  }

  componentDidMount () {
    if (this.hasUnfinishedReport()) {
      this.setState({ index: 1 })
      this.setState({ id: this.props.claim?.id ?? null })
    }

    this.props.navigation.addListener('beforeRemove', this.warnUser)
  }

  componentDidUpdate (prevProps: TClaimDamageFormProps) {
    if (
      this.props.policy?.productId &&
      prevProps.policy?.productId !== this.props.policy?.productId &&
      this.props.claimMethod?.extern !== true
    ) {
      this.props.apiCallDispatch(
        'GET',
        'PRODUCT',
        this.props.policy.productId.toString(),
        undefined,
        true
      )
    }

    if (this.props.formClaimDamage.answersStatic !== prevProps.formClaimDamage.answersStatic) {
      this.setState({ conceptButtonText: 'Concept opslaan' })
    }

    if (this.props.formClaimDamage.additionalFiles !== prevProps.formClaimDamage.additionalFiles) {
      this.handleFormClaimDamageChange()
    }
  }

  handleFormClaimDamageChange = () => {
    const additionalFiles = this.props.formClaimDamage.additionalFiles
    const loadingFiles = Object.keys(additionalFiles).filter(key => additionalFiles[key].loading === true)
    this.setState({ loadingFiles: loadingFiles.length !== 0 })
  }

  warnUser = (e) => {
    if (this.props.claimDamageId && this.state.index >= 1 && this.state.index < 3) {
      // Do not interrupt user when they haven't made any changes
      if (Object.keys(this.props.formClaimDamage.answersDynamic).length === 0) {
        return
      }

      e.preventDefault()

      Alert.alert('Opslaan', 'Wilt u de aanpassingen als concept opslaan?', [
        { text: 'Ja', style: 'default', onPress: this.saveAsConcept },
        { text: 'Nee', style: 'destructive', onPress: () => this.closeScreen(e) },
        { text: 'Annuleren' }
      ])
    }
  }

  closeScreen = (e) => {
    this.props.navigation.dispatch(e.data.action)
    this.props.resetFormClaimDamanage()
  }

  saveAsConcept = async (showConfirmation: boolean = false) => {
    this.setState({ savingProgress: true })

    let id: null | string | undefined = this.props.formClaimDamage.answersStatic.id ?? this.state.id

    if (id === null) {
      const policyNumber = this.props.policy.policyNumber
      const agentNumber = this.props.currentRelation.agentNumber
      const relationNumber = this.props.currentRelation.relationNumber
      const branch = this.props.policy.branche

      const createResult: IApiResponse<Types.ClaimConceptCreateResponse> = await this.props.apiCallRaw(
        myAppClaimsApi.createClaimConcept,
        policyNumber, agentNumber, relationNumber, branch
      )

      if (createResult.statusCode === 409) {
        this.setState({ serviceAvailable: false, serviceUnavailableErrorMessage: createResult.message ?? '' })
      } else {
        const data = createResult.data as Types.ClaimConceptCreateResponse

        if (data && data.id !== '') {
          this.setState({ id: data.id })
          this.props.updateFormClaimDamageStatic('id', data.id)
          id = data.id
        }
      }
    }

    if (id !== null && id !== '') {
      await this.props.apiCallRaw(
        myAppClaimsApi.updateClaimConcept,
        id,
        this.props.formClaimDamage.answersStatic.dateOfDamage,
        this.props.formClaimDamage.answersStatic.description,
        this.props.formClaimDamage.answersStatic.phoneNumber,
        this.props.policy.policyNumber
      )
    }

    this.setState({ savingProgress: false })
    if (showConfirmation) {
      this.setState({ conceptButtonText: 'Opgeslagen' })
    }

    return id ?? ''
  }

  hasUnfinishedReport = () =>
    this.isStaticFormValid() &&
    this.props.formClaimDamage.id !== null

  onChangePolicyId = (policyId: Types.Policy['id']) => {
    this.props.updateFormClaimDamageStatic('policyId', policyId)
    this.props.updateFormClaimDamageStatic('productClaimTypeName', undefined)
    this.props.setFormClaimDamageId(null)
  }

  isStaticFormValid = () =>
    this.props.formClaimDamage?.answersStatic?.dateOfDamage !== undefined &&
    this.props.formClaimDamage?.answersStatic?.description !== undefined &&
    this.props.formClaimDamage?.answersStatic?.description !== '' &&
    this.props.formClaimDamage?.answersStatic?.policyId !== undefined &&
    !!this.props.formClaimDamage?.answersStatic?.phoneNumber &&
    (this.props.formClaimDamage.answersStatic.phoneNumber?.length ?? 0) >= 10 &&
    !this.state.loadingFiles

  onNextStep = () =>
    this.setState(
      (previousState: TClaimDamageFormState) => ({
        availableIndex: previousState.index + 1,
        index: previousState.index + 1
      }),
      this.scrollToTop
    )

  onPreviousStep = () =>
    this.setState((previousState: TClaimDamageFormState) => ({
      index: previousState.index - 1
    }))

  scrollToTop = (): void => {
    setTimeout(() => {
      if ('scrollTo' in this.scrollViewRef?.current) {
        this.scrollViewRef?.current?.scrollTo({ animated: true, x: 0, y: 0 })
      } else if ('scrollToPosition' in this.scrollViewRef?.current) {
        this.scrollViewRef?.current?.scrollToPosition(0, 0, true)
      }
    }, 1) // to ensure android also scrolls to top all the time
  }

  finishStepStatic = async () => {
    this.setState({
      dateOfDamageError: false,
      descriptionError: false,
      phoneNumberError: false,
      dateOfDamageErrorMessage: ''
    })

    const maximumDateOfLossDate = this.props.policy.maximumDateOfLoss ? new Date(this.props.policy.maximumDateOfLoss) : null
    const mimimumDateOfLossDate = new Date(this.props.policy.minimumDateOfLoss)
    const dateOfDamageDate = new Date(this.props.formClaimDamage?.answersStatic?.dateOfDamage ?? '')

    if (this.props.formClaimDamage?.answersStatic?.dateOfDamage === undefined) {
      this.setState({
        dateOfDamageError: true
      })
    } else if (mimimumDateOfLossDate > dateOfDamageDate) {
      this.setState({
        dateOfDamageError: true,
        dateOfDamageErrorMessage: `De datum van schade moet na ${dateUtils.formatTimeStamp(this.props.policy.minimumDateOfLoss)} zijn. Is uw schade eerder ontstaan? Neem dan contact op met uw adviseur.`
      })
    } else if (maximumDateOfLossDate && dateOfDamageDate > maximumDateOfLossDate) {
      this.setState({
        dateOfDamageError: true,
        dateOfDamageErrorMessage: `De datum van schade moet voor ${dateUtils.formatTimeStamp(this.props.policy.maximumDateOfLoss)} zijn. Is uw schade later ontstaan? Neem dan contact op met uw adviseur.`
      })
    }

    if (this.props.formClaimDamage?.answersStatic?.description === undefined || this.props.formClaimDamage.answersStatic.description === '') {
      this.setState({
        descriptionError: true
      })
    }

    if (this.props.formClaimDamage?.answersStatic?.phoneNumber === undefined || (this.props.formClaimDamage.answersStatic.phoneNumber?.length ?? 0) < 10) {
      this.setState({
        phoneNumberError: true
      })
    }

    if (this.isStaticFormValid()) {
      this.onNextStep()
    }
  }

  finishStepSummary = async () => {
    this.setState({ loadingSummary: true })

    let id: null | string | undefined = this.props.formClaimDamage.answersStatic.id ?? this.state.id

    if (id == null) {
      const policyNumber = this.props.policy.policyNumber
      const agentNumber = this.props.currentRelation.agentNumber
      const relationNumber = this.props.currentRelation.relationNumber
      const branch = this.props.policy.branche

      const createResult: IApiResponse<Types.ClaimConceptCreateResponse> = await this.props.apiCallRaw(
        myAppClaimsApi.createClaimConcept,
        policyNumber, agentNumber, relationNumber, branch
      )

      if (createResult.statusCode === 409) {
        this.setState({ serviceAvailable: false, serviceUnavailableErrorMessage: createResult.message ?? '' })
      } else {
        const data = createResult.data as Types.ClaimConceptCreateResponse

        if (data && data.id !== '') {
          this.setState({ id: data.id })
          id = data.id
        }
      }
    }

    if (id !== null && id !== '') {
      await this.props.apiCallRaw(
        myAppClaimsApi.storeClaimConcept,
        id,
        this.props.formClaimDamage.answersStatic.dateOfDamage,
        this.props.formClaimDamage.answersStatic.description,
        this.props.formClaimDamage.answersStatic.phoneNumber,
        this.props.policy.policyNumber
      )
    }

    this.onNextStep()

    this.setState({ loadingSummary: false })
  }

  renderContent = (layout: 'phone' | 'tablet') => {
    if (this.props.claimMethod?.extern) {
      return (
        <StepExtern
          layout={layout}
          onChangePolicyId={this.onChangePolicyId}
        />
      )
    }

    switch (this.state.index) {
      case 0:
        return (
          <StepStatic
            loadingStatic={this.state.loadingStatic}
            staticFormValid={this.isStaticFormValid()}
            onChangePolicyId={this.onChangePolicyId}
            updateFormStatic={this.props.updateFormClaimDamageStatic}
            finishStepStatic={this.finishStepStatic}
            saveAsConcept={this.saveAsConcept}
            conceptButtonText={this.state.conceptButtonText}
            descriptionError={this.state.descriptionError}
            phoneNumberError={this.state.phoneNumberError}
            dateOfDamageError={this.state.dateOfDamageError}
            dateOfDamageErrorMessage={this.state.dateOfDamageErrorMessage}
          />
        )
      case 1:
        return (
          <StepSummary
            loadingSummary={this.state.loadingSummary}
            onPreviousStep={this.onPreviousStep}
            finishStepSummary={this.finishStepSummary}
          />
        )
      default:
        return <StepResult viewClaimDamageReport={() => navigation.replaceScreenClaim(this.props.formClaimDamage.answersStatic.id)} />
    }
  }

  renderContentTablet = () =>
    this.props.claimMethod?.extern
      ? (
        <Bootstrap.Row>
          <Bootstrap.Column
            span={{ lg: 12, md: 12, sm: 12, xl: 12, xs: 12, xxl: 12, xxxl: 10 }}
            style={Styling.autoHorizontal}
          >
            <Block>{this.renderContent('tablet')}</Block>
          </Bootstrap.Column>
        </Bootstrap.Row>
        )
      : (
        <Bootstrap.Row>
          <Bootstrap.Column
            offsetLeft={{ lg: 1, md: 0, sm: 0, xl: 2, xs: 0, xxl: 3 }}
            span={{ lg: 10, md: 12, sm: 12, xl: 8, xs: 12, xxl: 6 }}
            style={Styling.itemsCenter}
          >
            <Text
              center
              style={Styling.marginBottom}
              type='title'
            >
              {i18n.t('Schade melden')}
            </Text>
            <Text
              center
              type='description'
            >
              {i18n.t('Doorloop het formulier om de opgelopen schade te melden.')}
            </Text>
            <Stepper
              availableIndex={this.state.availableIndex}
              index={this.state.index}
              labels={this.stepperItems}
              onIndexChange={(index) => this.setState({ index })}
              style={Styling.marginVerticalMedium}
            />
          </Bootstrap.Column>
          <Bootstrap.Column
            span={{ lg: 12, md: 12, sm: 12, xl: 10, xs: 12, xxl: 9 }}
            style={Styling.autoHorizontal}
          >

            {this.state.serviceAvailable
              ? <Block noFlex>{this.renderContent('tablet')}</Block>
              : <Text type='description'>{this.state.serviceUnavailableErrorMessage}</Text>}
          </Bootstrap.Column>
        </Bootstrap.Row>
        )

  renderPhone = () => {
    const showOverlaySpinner =
      this.props.loadingPolicyProduct ||
      this.state.savingProgress

    return (
      <Screen
        white
        scrollViewRef={this.scrollViewRef}
      >
        <OverlaySpinner visible={showOverlaySpinner} />
        {this.state.serviceAvailable
          ? this.renderContent('phone')
          : <Text type='description'>{this.state.serviceUnavailableErrorMessage}</Text>}
      </Screen>
    )
  }

  renderTablet = () => {
    const showOverlaySpinner =
      this.props.loadingPolicyProduct ||
      this.state.savingProgress

    return (
      <Screen scrollViewRef={this.scrollViewRef}>
        <OverlaySpinner visible={showOverlaySpinner} />
        {this.renderContentTablet()}
      </Screen>
    )
  }

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const mapStateToProps = (state: TState) => {
  const formClaimDamage = selectors.getFormClaimDamage(state)
  const policy = selectors.getPolicy(state, formClaimDamage?.answersStatic?.policyId) ?? selectors.getPolicyByPolicyNumber(state, formClaimDamage.answersStatic.policyId ?? '')
  const claim = selectors.getClaimFromDamageForm(state)
  const claimMethod = selectors.getClaimMethod(state, policy?.id)
  const loadingClaimAttachments = selectors.isLoading('CLAIM_ATTACHMENTS')(state)
  const currentRelation = selectors.getCurrentRelation(state)
  const user = selectors.getUser(state)

  return {
    claim,
    claimMethod,
    formClaimDamage,
    policy,
    loadingClaimAttachments,
    currentRelation,
    user,
    agency: selectors.getAgency(state),
    claimDamageId: selectors.getFormClaimDamageId(state),
    colors: selectors.getThemeColors(state),
    emptyQuestions: selectors.getFormClaimDamageEmptyQuestions(state),
    formResult: selectors.getFormClaimDamageResult(state),
    loadingQuestions: !!selectors.isLoading('CLAIM_DAMAGE_QUESTIONS', claim?.claimType)(state),
    loadingPolicyProduct: !!selectors.isLoading('PRODUCT', policy?.productId?.toString())(state),
  }
}

const mapDispatchToProps = {
  apiCallDispatch,
  apiCallSuccess,
  openModalEmergencyNumber,
  removeClaimAttachment,
  resetFormClaimDamanage,
  setFormClaimDamageId,
  updateFormClaimDamageDynamic,
  updateFormClaimDamageStatic,
  uploadClaimAttachment
}

export default connect(mapStateToProps, mapDispatchToProps)(withApiCall(ClaimDamageForm))
